import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { LOCALE_ID, NgModule } from "@angular/core";
import { AngularFireModule } from "@angular/fire";
import { AngularFireAuthModule } from "@angular/fire/auth";
import { AngularFireAuthGuardModule } from "@angular/fire/auth-guard";
import { AngularFirestoreModule } from "@angular/fire/firestore";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { ServiceWorkerModule } from "@angular/service-worker";
import { NgxOneSignalModule } from "ngx-onesignal";
import { ToastrModule, ToastrService } from "ngx-toastr";
import { environment } from "src/environments/environment";
import { AdminLayoutModule } from "./admin-layout/admin-layout.module";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { LoginModule } from "./login/login.module";
import { AuthTokenInterceptor } from "./utils/auth-interceptor";
import { AlertComponent } from "./_components/alert/alert.component";
@NgModule({
  declarations: [AppComponent, AlertComponent],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule,
    AngularFireAuthModule,
    AngularFireAuthGuardModule,
    BrowserAnimationsModule, // required animations module
    ToastrModule.forRoot(),
    ServiceWorkerModule.register("OneSignalSDKWorker.js", {
      enabled: environment.production,
    }),
    NgxOneSignalModule.forRoot({
      appId: environment.ONE_SIGNAL_APP_ID,
    }),
    HttpClientModule,
    AdminLayoutModule,
    LoginModule,
    AppRoutingModule,
  ],
  providers: [
    ToastrService,
    { provide: LOCALE_ID, useValue: "en-IN" },
    { provide: HTTP_INTERCEPTORS, useClass: AuthTokenInterceptor, multi: true },
  ],
  bootstrap: [AppComponent],
  exports: [AlertComponent],
})
export class AppModule {}
