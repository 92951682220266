import { Injectable } from "@angular/core";
import { AngularFireAuth } from "@angular/fire/auth";
import { DbService } from "./db.service";

@Injectable({
  providedIn: "root",
})
export class AuthenticationService {
  constructor(private firebaseAuth: AngularFireAuth, private db: DbService) {}
  logout() {
    this.firebaseAuth.auth.signOut();
  }
  login(email: string, password: string) {
    return this.firebaseAuth.auth.signInWithEmailAndPassword(email, password);
  }
  isLogin() {
    return this.firebaseAuth.authState;
  }
  async register(email: string, password: string, name: string) {
    return await this.firebaseAuth.auth
      .createUserWithEmailAndPassword(email, password)
      .then(async (result) => {
        this.SendVerificationMail();
        console.log(result);
        let user = {
          email: result.user.email,
          emailVerified: result.user.emailVerified,
          userId: result.user.uid,
          name: name,
        };
        await this.db.save("Users", user, result.user.uid);
        return this.firebaseAuth.auth.signOut();
      })
      .catch((error) => {
        window.alert(error.message);
      });
  }
  SendVerificationMail() {
    return this.firebaseAuth.auth.currentUser.sendEmailVerification();
  }
}
