import { Component, OnInit } from "@angular/core";
import { AuthenticationService } from "../services/authentication.service";

@Component({
  selector: "app-admin-layout",
  templateUrl: "./admin-layout.component.html",
  styleUrls: ["./admin-layout.component.css"],
})
export class AdminLayoutComponent implements OnInit {
  constructor(private auth: AuthenticationService) {}

  ngOnInit(): void {
    this.auth.isLogin().subscribe((user) => {
      if (user) {
        console.log("user:", user);
      } else {
        console.log("not logged in");
      }
    });
  }
}
