import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class OnesignalHttpService {
  constructor(private http: HttpClient) {}

  setExternalId(userId: string, externalId) {
    let payload = {
      external_user_id: externalId,
    };
   return this.http.put(`https://onesignal.com/api/v1/players/${userId}`, payload);
  }
}
