import {
  HttpHandler,
  HttpHeaders,
  HttpInterceptor,
  HttpRequest,
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";

@Injectable()
export class AuthTokenInterceptor implements HttpInterceptor {
  private APIToken = null;
  private defaultApplicationHeaders = {
    "Content-Type": "application/json",
  };

  buildRequestHeaders(): HttpHeaders {
    let headers = this.defaultApplicationHeaders;

    // set API-Token if available
    if (this.APIToken !== null) {
      let authHeaderTpl = `Basic ${this.APIToken}`;
      headers["Authorization"] = authHeaderTpl;
    }

    return new HttpHeaders(headers);
  }

  constructor() {
    this.APIToken = environment.ONE_SIGNAL_API_KEY;
  }

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    const headers = this.buildRequestHeaders();
    const authReq = req.clone({ headers });

    return next.handle(authReq);
  }
}
