<div class="wrapper">
  <form class="form-signin" autocomplete="off">
    <h2 class="form-signin-heading">Login</h2>
    <input
      type="email"
      class="form-control MB10"
      name="username"
      [(ngModel)]="useremail"
      placeholder="Email Address"
      autofocus=""
    />
    <input
      type="password"
      class="form-control"
      name="password"
      [(ngModel)]="password"
      placeholder="Password"
    />

    <button
      class="btn btn-lg btn-primary pwc-btn-primary btn-block"
      (click)="onLogin()"
      type="submit"
    >
      Login
    </button>
    <a
      class="float-right"
      [routerLink]="['/auth/register']"
      routerLinkActive="router-link-active"
      >Register</a
    >
  </form>
</div>
