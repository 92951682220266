import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { AuthenticationService } from "../services/authentication.service";
import { DbService } from "../services/db.service";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.css"],
})
export class LoginComponent implements OnInit {
  useremail = "";
  password = "";
  constructor(
    private auth: AuthenticationService,
    private router: Router,
    private alert: ToastrService,
    private db: DbService
  ) {}

  ngOnInit(): void {}
  onLogin() {
    this.auth
      .login(this.useremail, this.password)
      .then((result) => {
        console.log(result);
        if (result.user.emailVerified) {
          this.db.update(
            "Users",
            { emailVerified: result.user.emailVerified },
            result.user.uid
          );
          this.router.navigateByUrl("admin");
        } else {
          this.auth.logout();
          this.alert.error("Please Verify your email first", "Failed to login");
        }
      })
      .catch((err) => console.log(err));
  }
}
