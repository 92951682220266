<nav class="navbar navbar-expand-lg navbar-light fixed-top">
  <button class="navbar-toggler" type="button" (click)="toggleSidebar()">
    <i class="fa fa-bars text-white" aria-hidden="true"></i>
  </button>
  <div _ngcontent-owk-c2="" class="navbar-brand">Demo</div>

  <div class="collapse navbar-collapse">
    <ul class="saga mt-2 mt-lg-0 navbar-nav ml-auto" id="saga">
      <li class="nav-item">
        <a class="nav-link notynoty" id="openNotifier" href="javascript:;">
          <i class="fa fa-bell text-white"></i>
          <span class="text-danger badge">3</span></a
        >
      </li>

      <li class="nav-item">
        <a class="nav-link msgmsg" href="javascript:;"
          ><span class="label label-warning badge"></span>
          <i class="fa fa-envelope text-white"></i
        ></a>
      </li>

      <li class="nav-link" (click)="logout()">
        <a class="nav-link msgmsg linklink text-white"
          ><i class="fa fa-user"></i> Logout</a
        >
      </li>
    </ul>
  </div>
</nav>
