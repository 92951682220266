import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { AuthenticationService } from "src/app/services/authentication.service";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.css"],
})
export class HeaderComponent implements OnInit {
  SidebarClass: string;

  constructor(private auth: AuthenticationService, private router: Router) {}

  ngOnInit() {
    this.SidebarClass = "sidebar-right";
  }

  toggleSidebar() {
    const dom: any = document.querySelector("body");
    dom.classList.toggle(this.SidebarClass);
  }
  logout() {
    console.log("logout");
    this.auth.logout();
    this.router.navigateByUrl("auth");
  }
}
