import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { AngularFireAuthModule } from "@angular/fire/auth";
import { AngularFireAuthGuardModule } from "@angular/fire/auth-guard";
import { AngularFirestoreModule } from "@angular/fire/firestore";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatCardModule, MatTableModule } from "@angular/material";
import { SortByPipe } from "../pipe/sort-by.pipe";
import { HeaderComponent } from "../_components/header/header.component";
import { SidebarComponent } from "../_components/sidebar/sidebar.component";
import { AdminLayoutRoutingModule } from "./admin-layout-routing.module";
import { AdminLayoutComponent } from "./admin-layout.component";
import { DashboardComponent } from "./dashboard/dashboard.component";
import { SettingsComponent } from "./settings/settings.component";

@NgModule({
  declarations: [
    AdminLayoutComponent,
    SidebarComponent,
    SortByPipe,
    HeaderComponent,
    DashboardComponent,
    SettingsComponent,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    AngularFirestoreModule,
    AngularFireAuthModule,
    AngularFireAuthGuardModule,
    AdminLayoutRoutingModule,
    FormsModule,
    MatTableModule,
    MatCardModule,
  ],
})
export class AdminLayoutModule {}
