import { Component, OnInit } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { AuthenticationService } from "src/app/services/authentication.service";

@Component({
  selector: "app-register",
  templateUrl: "./register.component.html",
  styleUrls: ["./register.component.css"],
})
export class RegisterComponent implements OnInit {
  username: string = "";
  useremail: string = "";
  password: string = "";
  constructor(
    private auth: AuthenticationService,
    private alert: ToastrService
  ) {}

  ngOnInit(): void {}

  async register() {
    await this.auth.register(this.useremail, this.password, this.username);

    this.alert.success("Verification Email Send");
  }
}
