// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  ONE_SIGNAL_APP_ID: "28225db6-8c7d-44ff-a9ed-4c028590b6c5",
  ONE_SIGNAL_API_KEY: "ZTg5MmE4ZjctY2FkYi00NzI3LWI1MDAtYTQwY2Y2MWQ4OTMy",
  firebase: {
    apiKey: "AIzaSyCju61BY1qwT8r5T_1F-Pkfp7BmFjeudt8",
    authDomain: "socialnetwork-58355.firebaseapp.com",
    databaseURL: "https://socialnetwork-58355.firebaseio.com",
    projectId: "socialnetwork-58355",
    storageBucket: "socialnetwork-58355.appspot.com",
    messagingSenderId: "233166284309",
    appId: "1:233166284309:web:f515ae16c2d314d3f4101c",
    measurementId: "G-Q8BLGJDSBE",
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
