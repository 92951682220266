import { NgModule } from "@angular/core";
import {
  AngularFireAuthGuard,
  redirectLoggedInTo,
  redirectUnauthorizedTo,
} from "@angular/fire/auth-guard";
import { RouterModule, Routes } from "@angular/router";

const redirectUnauthorizedToLogin = () => redirectUnauthorizedTo(["auth"]);
const redirectLoggedInAdmin = () => redirectLoggedInTo(["admin"]);
const routes: Routes = [
  {
    path: "",
    redirectTo: "auth",
    pathMatch: "prefix",
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: redirectLoggedInAdmin },
  },
  {
    path: "admin",
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin },
    loadChildren: () =>
      import("./admin-layout/admin-layout.module").then(
        (m) => m.AdminLayoutModule
      ),
  },

  {
    path: "auth",
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: redirectLoggedInAdmin },
    loadChildren: () =>
      import("./login/login.module").then((m) => m.LoginModule),
  },
  {
    path: "**",
    redirectTo: "auth",
    pathMatch: "prefix",
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: "enabled",
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
