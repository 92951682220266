import { Component, OnInit } from "@angular/core";
import { AngularFirestore } from "@angular/fire/firestore";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatTableDataSource } from "@angular/material";
import { SwUpdate } from "@angular/service-worker";
import { firestore } from "firebase";
import { OneSignalService } from "ngx-onesignal";
import { ToastrService } from "ngx-toastr";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { OnesignalHttpService } from "src/app/services/onesignal.service";
@Component({
  selector: "app-dashboard",
  templateUrl: "./dashboard.component.html",
  styleUrls: ["./dashboard.component.css"],
})
export class DashboardComponent implements OnInit {
  title = "firebase-notification";
  messageList$: Observable<any[]>;
  form: FormGroup;

  onSave() {
    if (!this.form.valid) return;
    console.log("clicked", this.form.value);
    this.afs.collection("message").add({
      message: this.form.value.message,
      date: firestore.FieldValue.serverTimestamp(),
    });
  }
  constructor(
    private fb: FormBuilder,
    private afs: AngularFirestore,
    public readonly onesignal: OneSignalService,
    private swUpdate: SwUpdate,
    private onesignalhttp: OnesignalHttpService,
    private alert: ToastrService
  ) {
    this.messageList$ = this.afs.collection("message").valueChanges();
    (window as any).ngxOnesignal = this.onesignal;
  }
  ngOnInit() {
    this.form = this.fb.group({
      message: ["", Validators.required],
    });
    this.pushSubscription();
    if (this.swUpdate.isEnabled) {
      this.swUpdate.available.subscribe(() => {
        if (confirm("New version available. Load New Version?")) {
          window.location.reload();
        }
      });
    }
    this.subcollection();
  }

  async deleteNote(item) {
    let id = await this.afs
      .collection("message", (ref) => ref.where("date", "==", item.date))
      .get()
      .pipe(map((res) => res.docs[0].id))
      .toPromise();
    this.afs
      .collection("message")
      .doc(id)
      .delete()
      .then(() => this.alert.error("Deleted"));
  }
  pushSubscription() {
    this.onesignal.isPushNotificationsEnabled$.subscribe((isPushEnabled) => {
      console.log(isPushEnabled);
      this.onesignal.subscribeState$.subscribe((isSubscribe) => {
        console.log("isSubscribe" + JSON.stringify(isSubscribe));
        if (!isSubscribe.isSubscribed) {
          // TODO request to subscribe
        } else {
          let userId = this.onesignal.userId;

          this.onesignalhttp.setExternalId(userId, "asa");
        }
      });
    });
  }
  dataList = new MatTableDataSource();
  displayedColumns: string[] = ["index", "message", "date", "action"];
  subcollection() {
    this.afs
      .collection("message", (ref) => ref.orderBy("date", "desc"))
      // .doc("o1wKLVOr8wN64O0B3BYU2QPxwwg1")
      // .collection("message")
      .valueChanges()
      .subscribe((res) => {
        console.log(res);
        this.dataList.data = res;
      });
  }

  onSwipe(event) {
    console.log(event);
  }
}
