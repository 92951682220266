import { Injectable } from "@angular/core";
import { AngularFirestore } from "@angular/fire/firestore";

@Injectable({
  providedIn: "root",
})
export class DbService {
  constructor(private db: AngularFirestore) {}

  save(collection: string, data: any, docId = "") {
    if (docId && docId != "") {
      return this.db.collection(collection).doc(docId).set(data);
    } else {
      return this.db.collection(collection).add(data);
    }
  }

  update(collection: string, data: any, docId: string) {
    return this.db.collection(collection).doc(docId).update(data);
  }
}
